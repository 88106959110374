<template>
  <Layout>
    <FiberLocationsPrint />
  </Layout>
</template>

<script>
import Layout from '@layouts/schedulerLayout'
import FiberLocationsPrint from '@components/SchedulerView/FiberLocationsPrint'
import { createHelpers } from 'vuex-map-fields'
const { mapFields } = createHelpers({
  getterType: 'getSchedulerField',
  mutationType: 'updateSchedulerField',
})

export default {
  name: 'FiberLocationPrintView',
  components: { Layout, FiberLocationsPrint },
  computed: {
    ...mapFields(['isViewingFiberLocationPrint']),
  },
  mounted() {
    this.isViewingFiberLocationPrint = true
  },
  beforeDestroy() {
    this.isViewingFiberLocationPrint = false
  },
}
</script>
