<template>
  <VContainer fluid class="pa-0">
    <ColumnTitle class="no-print"
      >Unscheduled Fiber Locations ({{ visibleLocations.length }})</ColumnTitle
    >
    <VContainer fluid grid-list-md py-0 px-1>
      <VLayout align-center>
        <transition name="fade-fast" mode="out-in">
          <VLayout v-if="loading" align-center>
            <VFlex grow>
              <BaseSpinner message="Loading Locations" />
            </VFlex>
          </VLayout>
          <VFlex v-else-if="visibleLocations.length" class="fiber-locations">
            <LocationItem
              v-for="location in visibleLocations"
              :key="location.ID"
              :location="location"
            />
          </VFlex>
          <VFlex v-else class="pa-4">
            <h3 class="dark white--text">No Locations Found</h3>
          </VFlex>
        </transition>
      </VLayout>
    </VContainer>
  </VContainer>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

import LocationItem from './LocationItem'
import ColumnTitle from './ColumnTitle'
import _get from 'lodash/get'
import _sortBy from 'lodash/sortBy'
import dayjs from 'dayjs'

import { createHelpers } from 'vuex-map-fields'
const { mapFields } = createHelpers({
  getterType: 'getSchedulerField',
  mutationType: 'updateSchedulerField',
})

export default {
  name: 'FiberLocationsList',
  metaInfo: {
    title() {
      return 'Fiber Locations for ' + dayjs().format('MM-DD-YYYY')
    },
  },
  components: {
    LocationItem,
    ColumnTitle,
  },
  props: {
    loading: {
      type: Boolean,
      default: true,
    },
  },

  data() {
    return {}
  },
  computed: {
    ...mapGetters(['canEditScheduler', 'getSortedLocations']),
    ...mapFields(['isLocationDrawerMini']),
    sortedLocations() {
      return _sortBy(
        this.getSortedLocations,
        ['CLIENT_NAME', 'JOB_NUMBER'],
        ['asc', 'desc']
      )
    },
    visibleLocations() {
      return this.sortedLocations.filter(
        (location) => location.NUMBER_OF_DAILY_REPORTS === 0
      )
    },
    isDeleteZone() {
      let isReportObject = _get(this, 'theDragObject.locationData', false)
      return this.isDragging && isReportObject
    }, // isDeleteZone
    isForPrint() {
      return this.$route.path === '/print-fiber-locations'
    },
  }, // computed
  methods: {
    ...mapActions(['clearLocationFilters']),
  }, // methods
}
</script>

<style lang="scss">
.fiber-locations {
  display: flex;
  flex-wrap: wrap;
  .location-item {
    $w: 100% / 8;
    flex: 0 0 calc($w - 2px);
    width: calc($w - 2px);
    border-style: solid;
    border-color: #6e6e6e !important;
    border-width: 1px !important;
    border-radius: 0;
    margin: 1px;
    padding: 5px;
    min-height: 130px;
    .permit-hours {
      font-size: 13px;
    }
    .new-permit-icon {
      top: 0;
      right: 0;
      position: absolute;
    }
  }
}
</style>
